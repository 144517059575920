import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * List scheduler entries from client
 * @returns object
 */
export const listSchedulePoints = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      query {
        queueWorkers(where:{client:[${client_id}], Completed_null: true, active:${1}}) {
          id
          Type
          JsonData
          RepeatType
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Create entry for schedule points
 * @param {object} values
 * @returns object
 */
export const createSchedulePoints = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Get current reseller
  // let reseller_id = localStorage.getItem("reseller_id");
  axiosOptions.data = {
    query: `
      mutation{
        createQueueWorker(
          input:{
            data:{
              Type: ${values.add_set}
              JsonData:{
                DivisionCategoryId: ${values.division_category},
                Points: ${values.number_of_points},
                ${values.note ? 'Note: "' + values.note + '",' : ""}
                ${
                  values.userIds
                    ? "UserIds: " +
                      JSON.stringify(values.userIds).replace(
                        /"(\w+)"\s*:/g,
                        "$1:"
                      ) +
                      ","
                    : ""
                }
                Timezone:"${timezone}"
              }
              ${values.date ? `Start: "${values.date}"` : ""}
              ${values.frequency ? "RepeatType: " + values.frequency : ""}
              ${
                values.frequency && values.frequency_limit
                  ? "RepeatValue: " + values.frequency_limit
                  : ""
              }
              client: ${values.client_id}
              active: ${true}
            }
          }
        ){
          queueWorker{
            id
            Type
            JsonData
            Start
            RepeatType
            RepeatValue
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log("error -> ", error);
    return error;
  }
};

/**
 * Delete entry for schedule points
 * @param {int} id
 * @returns object
 */
export const deleteSchedulePoints = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        updateQueueWorker(input: { where: { id: ${id} }, data: { active: ${false} } }) {
          queueWorker {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Get the last points from user
 * @returns object
 */
export const getUserPoints = async (user_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      query{
        userPoints(user_id:${user_id}){
          DivisionName
          division_category_id
          points_balance
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create a new points record
 * DEPRECATED - We need to control the sending of email notifications when points are added, 
 * so you should use createUserPoints which already has the notification functionality integrated
 * @param {*} user_id
 * @param {*} points
 * @returns
 */
export const createPoints = async (user_id, points) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // mutation
  axiosOptions.data = {
    query: `
      mutation{
        createPoint(
          input:{
            data:{
              user_id:${user_id},
              division_category:${points.id},
              TransactionAmount:${points.balance - points.original_balance},
              Balance:${points.balance},
              Message:"Added ${points.balance - points.original_balance} points"
            }
          }
        )
        {
          point{
            id
            Message
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create a new user points record
 * @param {*} userId
 * @param {*} points
 * @returns API response - Strapi API
 */
export const createUserPoints = async (userIid, divisionCategories) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // mutation
  axiosOptions.data = {
    query: `
      mutation {
        createUserPoints(
          userId: ${userIid}
          divisionCategories: ${JSON.stringify(divisionCategories).replace(
            /"(\w+)"\s*:/g,
            "$1:"
          )}
        ) {
          userId
          divisionCategories
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
